<template>
	<div>
		<headertop/>
		<div class="submitted" id="submitted" v-cloak ref="element">
			<div class="type bg-f">
				<div class="pwidth absolute l-0 r-0 h-100">
					<div class="logo f-24 relative  m-r-10 flex" @click="()=>{$router.push({path:'./index'})}" >
						<div class=" w-200 h-80 tc flex m-t-10 m-r-20"><img class=" w100 m-a" :src="$store.state.app.logourl" alt=""></div>
						<span class="c-3 f-24  tr p-r-100  relative t-10 l-h-80" v-if="$store.state.app.Operational">{{$store.state.app.Operational.goodlist.Orderapplication}} </span>
					</div>
		
					<div class="Stepbar m-t-20 m-l-30">
						<ul>
							<li class="tc">
								<div class="w-30 h-30 rad50 l-h-30 tc border-a-00b-1 c-f bg-color m-a">1</div>
								<p class="c-9 m-t-10" :class="[next==0||next==1||next==2?'fontactive':'']" v-if="$store.state.app.Operational">{{$store.state.app.Operational.goodlist.Orderapplication}}</p>
							</li>
							<b class="nexactive l-15"></b>
							<li class="tc">
								<div class="w-30 h-30 rad50 l-h-30 tc m-a border-a-00b-1 c-f bg-color" >2</div>
								<p class="c-9 m-t-10 fontactive " :class="[next==1||next==2?'fontactive':'']">填写核对订单信息</p>
							</li>
							<b :class=" [next==2?'nexactive r-15':'r-15']"></b>
							<li class="tc">
								<div :class="next!=2?'b-a-9 c-9':'border-a-00b-1 c-f bg-color'" class="w-30 h-30 rad50 l-h-30 tc m-a" >3</div>
								<p class="c-9 m-t-10 " :class="[next==2?'fontactive':'']">提交成功</p>
							</li>
						</ul>
					</div>
				</div> 
		
			</div> 
		
		
		
			<!-- 提交订单 -->
			<div class="placeorder" v-if="next==0">
		   
				<div class="pwidth bg-f  p-b-10 m-b-10">
					<div class="p-l-20 p-t-20 p-r-20" v-if="close">
					<h3  class="c-6 f-14 h-40   l-h-40  tishi p-0-20 relative  tip"><i class="el-icon-warning"></i> 填写订货申请单时请仔细核对订单信息 <i @click="close=false" class="el-icon-close c-3 absolute r-20 t-13"></i> </h3>
						
					</div>
		
					<div class="p-0-20 p-t-20">
						<div>
							<h3 class=" bold"><i class="shu-2 m-r-5"></i> 收货公司</h3>
							<div class="m-t-15 f-16 p-l-10">{{companyname}}</div>
						</div>
						<div>
							<div class="flex">
								<h3 class="m-t-20 bold"><i class="shu-2 m-r-5"></i> 收货人信息</h3>
								<p class="m-t-20  f-14 cursor c-00b" @click="addressbtn(0)">新增收货地址</p>
							</div>
		
							<div class="border-a-e p-20 m-t-10 address" v-if="addresslist.length">
								<ul>
									<li class="flex start m-b-10 cursor" v-for="(item,index) in addresslist"
										@click="selectaddress(index)">
										<div class="border-a-d w-100 h-30 l-h-30 m-r-40 tc relative" :class="selectaddresskey==index?'border-a-00b l-h-29':''">{{item.receiptname}}
											<i class="iconfont c-b14e absolute b-6 r--2 w-16 h-16 l-h-11" v-if="selectaddresskey==index">&#xe693;</i>
										</div>
										<div class="l-h-30 flex  w100">
											<p>
												<span class="m-r-20">{{item.phone}}</span>
												<span class="m-r-10">{{item.areadetail}}{{item.address}}</span>
											</p>
											<p class="c-6" @click="addressbtn(item)"> 
											<i class="iconfont c-6">&#xe69a;</i>	编辑</p>
											<!-- <img class="m-r-10" src="../../assets/img/bj.png" alt=""> -->
											
										</div>
									</li>
								</ul>
								
							</div>
						</div>
		
						<div v-if="paytype.length">
							<div class="flex bold" v-if="$store.state.app.Operational">
								<!-- 支付方式 -->
								<h3 class="m-t-20 bold"><i class="shu-2 m-r-5"></i> {{$store.state.app.Operational.goodlist.paytype}}
								</h3>
							</div>
		
							<div class="border-a p-20 m-t-10">
								<ul class="flex start">
									<li class="m-r-10 cursor" v-for="(item,index) in paytype" @click="selectpaytype(index)">
										<div class="border-a-d h-30 l-h-30  tc p-0-10 relative" v-if="item.payid!=5"
											:class="selectpaytypekey==index?'border-a-00b l-h-29':''">
											<img class="w-20 h-20 relative t--2" :src="item.icon">
											<span class="relative t--1" v-if="item.payid!=8">{{item.methodname}}</span>
											<span class="relative t--1" v-if="item.payid==8&&$store.state.app.Operational">{{$store.state.app.Operational.goodlist.OfflineTrading}}</span>
											<!-- <img src="../../assets/img/check-2.png"  class="w-12 h-12 absolute b-0 r-0"> -->
											
											
											<i class="iconfont c-b14e absolute b-6 r--2 w-16 h-16 l-h-11" v-if="selectpaytypekey==index">&#xe693;</i>
										</div>
		
		
										<div class="h-30 l-h-30  tc p-0-10 relative" v-if="item.payid==5&&erpcustno"
											:class="selectpaytypekey==index?'border-a-00b l-h-29':erpcustno?'border-a-d':''">
											<!-- <img class="w-20 h-20 relative t--2" v-if="item.payid==5&&erpcustno"
												src="../../assets/img/pay-5.png"> -->
											<img class="w-20 h-20 relative t--2" :src="item.icon">
											<span class="relative t--1"
												v-if="item.payid==5&&erpcustno">{{item.methodname}}</span>
											<span v-if="creditdata"
												class="f-10 c-ffa">(可用额度：{{creditdata.remainamount}})</span>
											<!-- <img src="../../assets/img/check-2.png" v-if="selectpaytypekey==index" class="w-12 h-12 absolute b-0 r-0"> -->
												<i class="iconfont c-b14e absolute b-6 r--2 w-16 h-16 l-h-11" v-if="selectpaytypekey==index">&#xe693;</i>
										</div>
									</li>
								</ul>
							</div>
						</div>
		
		
						<div class="">
							<div class="flex">
								<h3 class="m-t-20 bold"><i class="shu-2 m-r-5"></i> 商品信息</h3>
							</div>
		
							<ul class="m-t-10 m-b-10">
		
								<li class="" v-for="(item,index) in checkedgoods">
									<div class="m-r-10 cursor flex start bg-f9 h-100 p-10 m-b-10">
										<div class="w-80 h-80">
											<img class="w-80 h100" v-if="item.imageurl" :src="item.imageurl"
												onerror="javascript:this.src='../../assets/img/mr.png';">
											<img class="w-80 h100" v-if="!item.imageurl" src="../../assets/img/mr.png" alt="">
										</div>
										<div class="m-l-10 p-t-15 w100">
											<h3>
												<span> {{item.goodsname}}</span>
												<i v-if="item.isprescription">{{item.isprescription? 'RX':'' }}</i>
												<i v-if="item.isephedrine">{{item.isephedrine? '麻黄碱':''}}</i>
											</h3>
		
											<div class="m-t-15 flex start">
												<div class="w-160 " v-if="item.batchno=='defaultbno'"><span class="--b-a-1 --color tc h-16 l-h-10 p-2-5 f-12 i-b">默认分配</span>
												</div>
												<div class="w-160" v-else><span class="--b-a-1 --color tc h-16 p-2-5 f-12 i-b l-h-10 m-r-10"> 批号</span>{{item.batchno}}</div>
												<span class="w-200 m-r-25 ell">业务组：{{item.batchnosalegroupname}}</span>
												<span class="w-200 m-r-25 ell">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</span>
												<span class="ell">生产厂家：{{item.manufacturersname}}</span>
											</div>
										</div>
										<div class="flex-c s-c tr">
											<div class="w-150 f-16 bold m-b-10">x {{item.qty}}</div>
											
											<div v-if="$store.state.app.showprice!=1"><span class="f-12 c-f00">￥</span> <span class="f-20 c-f00 bold">{{item.price||item.seckill||0}}</span></div>
											
										</div>
									</div>
									<div class="m-r-10 cursor flex start bg-f9 h-100 p-10 m-b-10" v-if="item.gift">
										<div class="w-80 h-80">
											<img class="w-80 h100" v-if="item.gift.imageurl" :src="item.gift.imageurl"
												onerror="javascript:this.src='../../assets/img/mr.png';">
											<img class="w-80 h100" v-if="!item.gift.imageurl" src="../../assets/img/mr.png"
												alt="">
										</div>
										<div class="m-l-10 p-t-15 w100 title">
											<h3>
												<span class="c-ff8">【赠品】</span>
												<span>{{item.gift.goodsname}}</span>
												<i class="f-10 c-b14e border-a-00b-2 p-0-3 rad-4"
													v-if="item.gift.isprescription">{{item.gift.isprescription? 'RX':''
													}}</i>
												<i v-if="item.gift.isephedrine">{{item.gift.isephedrine? '麻黄碱':''}}</i>
											</h3>
		
											<div class="m-t-15 flex start">
		
												<p class="w-160"><span
														class="border-a-ff8-1 tc c-ff8 h-16 l-h-10 p-2-5 f-12 i-b">默认分配</span>
												</p>
												<!-- <span class="w-160" v-else>批号：{{item.gift.batchno}}</span> -->
												<span class="w-200 ell">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</span>
												<!--  <span class="w-160">批发价：￥{{item.gift.price}}</span> -->
												<span class="w-420 m-r-10">赠送数量：{{item.giftCount}}
													<span class=" m-l-10" v-if="item.giftCount>item.gift.stockqty"> <span
															class="m-l-10 m-r-10"> | </span> 实赠数量：{{item.giftCount>item.gift.stockqty?item.gift.stockqty:item.giftCount}}
														<span class="c-f00 m-l-10">库存数量{{item.gift.stockqty}}，赠品库存不足</span>
													</span></span>
												<span>生产厂家：{{item.gift.manufacturersname}}</span>
											</div>
										</div>
									</div>
								</li>
		
		
							</ul>
						</div>
		
		
					</div>
					
					<!-- 翻页 -->
					<div class="pwidth pagination tc">
						<div id="Pagination">
							<el-pagination background layout="prev, pager, next" :page-size="pagesize" @current-change="currentchange" :current-page="currentPage" :total="total"></el-pagination>
						</div>
					</div>
		
				</div>
		
		
				<!-- 留言 -->
		
				<div class="pwidth Leaving m-b-10 bg-f">
					<h2 class="f-16 l-h-50 p-0-20 tl bold"><i class="shu-2 m-r-5"></i> {{$store.state.app.Operational.goodlist.Leavingmessage}}</h2>
					<div class="p-l-20 p-r-20 p-b-20">
						<el-input class="w100" type="textarea" :rows="4"  placeholder="请填写采购留言" v-model="textarea" maxlength="500"> </el-input>
					</div>
		
				</div>
		
				<div class="pwidth place tc relative  " :class="isfixed?'fixed m-b-0 l-0 r-0':'m-b-40'">
					<p></p>
					<div class="tibtn flex end w100" v-if="$store.state.app.Operational">
		
						<div class="m-r-20 flex">
							<div class="m-r-20">{{$store.state.app.Operational.goodlist.Modeofdelivery}}：{{$store.state.app.Operational.goodlist.Offlinedistribution}}</div>
							<div class="m-r-20" v-if="cartpublic">运费：<span class="c-ff8 m-r-5 bold f-16">{{cartpublic.freightAmount+cartpublic.discountFreightAmount}}</span> </div>
							<div class="m-r-20" v-if="cartpublic">优惠：<span class="c-ff8 m-r-5 bold f-16">{{cartpublic.totalDiscountAmount}}</span> </div>
							<div>已选商品<span class="c-ff8 m-r-5 f-20">{{total}}</span> 件</div>
							<div class="m-l-20" v-if="$store.state.app.showprice!=1">总金额： <span class="c-f00">￥ <span class="f-20 bold">{{allcount}}</span></span></div>
						</div>
						<p class="cursor m-l-20" @click="submitorder"> {{$store.state.app.Operational.goodlist.placeorder}}</p>
					</div>
				</div>
			</div>
		
			<!-- 添加收货地址 -->
			<div class="alert addressdialog">
				<!-- :visible.sync="dialogTableVisible" -->
				<el-dialog :title="ruleForm.name?'编辑收货地址':'添加收货地址'" :visible.sync="dialogTableVisible">
					<div class="content">
		
						<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
							class="demo-ruleForm">
		
							<el-form-item label="收货公司" class="relative">
								<!-- prop="company" -->
								<!-- <el-cascader :options="querymycustomer" :props="optionProps"
									@change="addresschangecompany"
									v-model="ruleForm.company" class="w100"
									placeholder="请输入收货公司"></el-cascader> -->
								<div class="p-l-10 f-18 bold">{{companyname}}</div>
							</el-form-item>
		
							<el-form-item label="收货人" prop="name" class="relative">
								<el-input type="text" v-model="ruleForm.name" autocomplete="off"
									placeholder="请输入姓名"></el-input>
							</el-form-item>
		
							<el-form-item label="手机号码" prop="phone" class="relative">
								<el-input type="text" v-model="ruleForm.phone" maxlength="11" autocomplete="off"
									placeholder="请输入电话"></el-input>
							</el-form-item>
		
							<el-form-item label="所在区域" class="quyu" prop="addressinfo" >
								<el-cascader
									:options="province" :props="provinceProps"
									placeholder="请选择省份"
									v-model="ruleForm.shengid" @change="handleChangesheng" >
								</el-cascader>
								<el-cascader
									:options="city" placeholder="请选择市" :props="provinceProps"
									v-model="ruleForm.cityid" @change="handleChangecity">
								</el-cascader>
								<el-cascader
									:options="area" placeholder="请选择区域" :props="provinceProps"
									v-model="ruleForm.areaid" @change="handleChangearea">
								</el-cascader>
							</el-form-item>
		
							<el-form-item label="详细地址" prop="addressinfo" class="relative">
		
								<el-input class="addressinfo" type="text" v-model="ruleForm.addressinfo"
									autocomplete="off"
									placeholder="请输入您的详细地址"></el-input>
							</el-form-item>
		
		
		
							<!-- <el-form-item> -->
							<div class="btn  p-l-20 ">
								<!--  <div class=" tl p-l-80" @click="isdefaultbtn">
									<img class="w-16 h-16" src="../../assets/img/check-3.png" alt="" v-if="ruleForm.isdefault">
									<p class="w-16 h-16 rad-4 border-a-c inline" v-if="!ruleForm.isdefault"></p>
									设置默认地址<span class="c-9 f-12 m-l-10">注：每次下单将会默认使用此地址</span>
								</div> -->
								<div class="tc cursor flex s-c m-t-20">
									<el-popover v-if="ruleForm.id" placement="top" width="160" v-model="visible">
										<p>确定删除这地址吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text" @click="visible = false">取消
											</el-button>
											<el-button type="primary" size="mini" @click="deladdress(ruleForm.id)">
												确定
											</el-button>
										</div>
										<el-button slot="reference" class="deladdress p-0 m-r-20 f-14 c-3"><span class="c-3">删除</span> </el-button>
									</el-popover>
									<p class="w-100 tc f-20 sava f-14 m-l-30" @click="updateaddress('ruleForm',2)"> 保存</p>
								</div>
							</div>
							<!-- </el-form-item> -->
		
						</el-form>
					</div>
				</el-dialog>
			</div>
		
		
			<!-- 支付页面 二维码-->
	<!-- 		<div class="qrcodema hidden" ref="qrCodeUrl" v-if="paycode==0"></div> -->
			<div class="fix bg-0-5 w100 h100 top-0 qrcode o-h" v-if="paycode==1">
				<div class="qrcodema "><img :src="qrCodeUrl"/></div>
				<div class="bg-f h-500 w-400 rad-10 absolute l-0 r-0 top-0 b-0 m-a">
					<div class=" absolute b-10 p-20 w-400 tc">
						<p class="f-20 tc m-b-30">微信扫码支付</p>
						<div class="flex m-t-20 f-16 p-0-30">
							<p class="w-100 h-40 rad-4 border-a-00b-2 c-b14e l-h-40 cursor" @click="calcenpay">取消支付</p>
							<p class="w-100 h-40 rad-4 bg-00B c-f l-h-40 cursor" @click="Paymentsuccessful">支付完成</p>
						</div>
					</div>
				</div>
			</div>
		
		
			<!-- 提交成功 -->
			<div class="pwidth bg-f orderdetal m-t-20 tc" v-if="next!=0">
				<div class="orderimgs f-26"><img class="w-130 h-130" src="../../assets/img/succsee.png" alt=""> <p>订单提交成功</p></div>

				<div class="lookorder tc" v-if="$store.state.app.Operational">
					<div class="--b-a-1 w-120 m-r-40 rad-4 --color cursor" @click="succsee(0)">{{$store.state.app.Operational.goodlist.lookorder}}</div>
					<div class="--bg w-120 c-f rad-4 cursor" @click="succsee(1)">{{$store.state.app.Operational.goodlist.continuebrowse}}</div>
				</div>
			</div>
			<div class="h-40"></div>  
		</div>
		<footers/>
	</div>
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	import QRCode from 'qrcode'
	var toShoppingList = JSON.parse(sessionStorage.getItem('toShoppingArr'));
	var addtrade = sessionStorage.getItem('addtrade');
	var erpcustno = sessionStorage.getItem('erpcustno');
	var returngoods = sessionStorage.getItem('returngoods');
	var province = sessionStorage.getItem('province'); //省
	var creditdata = sessionStorage.getItem('credit');
	var companyname = sessionStorage.getItem('companyname'); //0客户公司名称
	var companycode = sessionStorage.getItem('companycode'); //公司编码
	var login = sessionStorage.getItem('login');
	export default{
		components:{footers,headertop,headers},
		data(){
			toShoppingList = JSON.parse(sessionStorage.getItem('toShoppingArr'));
			addtrade = sessionStorage.getItem('addtrade');
			erpcustno = sessionStorage.getItem('erpcustno');
			returngoods = sessionStorage.getItem('returngoods');
			province = sessionStorage.getItem('province'); //省
			creditdata = sessionStorage.getItem('credit');
			companyname = sessionStorage.getItem('companyname'); //0客户公司名称
			companycode = sessionStorage.getItem('companycode'); //公司编码
			login = sessionStorage.getItem('login');
			
			var _this = this;
			
			var checkcompany = function checkcompany(rule, value, callback) {
				if (!value) {return callback(new Error('请选择公司'));} else {callback();}
			};
			
			var checkname = function checkname(rule, value, callback) {
				if (!value) {return callback(new Error('姓名不能为空'));} else {callback();
				}
			};
			
			var checkshengid = function checkshengid(rule, value, callback) {
				if (!value) {return callback(new Error('姓名不能为空'));} else {callback();}
			};
			
			var checkcityid = function checkcityid(rule, value, callback) {
				if (!value) {return callback(new Error('姓名不能为空'));} else {callback();}
			};
			
			var checkareaid = function checkareaid(rule, value, callback) {
				if (!value) {return callback(new Error('姓名不能为空'));} else {callback();}
			};
			
			var checkaddressinfo = function checkaddressinfo(rule, value, callback) {
				if (!value) {return callback(new Error('请输入详细地址'));} else {callback();}
			};
			
			var checkphone = function checkphone(rule, value, callback) {
				if (!value) {
					_this.disabled = false;
					return callback(new Error('手机号不能为空'));
				}
				setTimeout(function() {
					if (!/[1]([3-9])[0-9]{9}$/.test(value)) {
						callback(new Error('手机号格式不正确'));
						_this.disabled = false;
					} else {
						_this.disabled = true;
						callback();
					}
				}, 1000);
			};
			
			return {
				close:true,
				//logo
				checkedgoods: [],
				allcount: toShoppingList ? toShoppingList.allcount : 0,
				//总金额
				next: 0,
				textarea: '',
				//留言
				// isSubmit: false,
				successafter: [],				//提交成功返回
				querymycustomer: null,				//收货公司
				isfixed: false,				//是否固定结算
				paytype: [],				//支付类型
				dialogTableVisible: false,				//是否显示添加地址弹窗
				// 新增地址参数
				ruleForm: {
					id: '',
					company: '',
					name: '',
					phone: '',
					shengid: '',
					cityid: '',
					areaid: '',
					addressinfo: '',
					isdefault: false
				},
				// 验证规则
				rules: {
					company: [{required: true,validator: checkcompany,trigger: 'change'}],
					name: [{required: true,validator: checkname,trigger: 'blur'}],
					phone: [{required: true,validator: checkphone,trigger: 'blur'}],
					shengid: [{required: true,validator: checkshengid,trigger: 'change'}],
					cityid: [{required: true,validator: checkcityid,trigger: 'change'}],
					areaid: [{required: true,validator: checkareaid,trigger: 'change'}],
					addressinfo: [{required: true,validator: checkaddressinfo,trigger: 'blur'}]
				},
				optionProps: {
					value: 'companycode',
					label: 'companyname',
					children: 'childrens'
				},
				provinceProps: {
					value: 'id',
					label: 'name',
					children: 'childrens'
				},
				province: [],				//省
				city: [],				//市
				area: [],				//区
				addresslist: [],				//获取的地址列表
				provinceid: '',				//选择的省id
				cityid: '',				//选择的市id
				areaid: '',				//选择的区id
				provinceidname: '',				//选择的省name
				cityidname: '',				//选择的市name
				areaidname: '',				//选择的区name
				selectaddresskey: -1,				//选择的地址key
				selectpaytypekey: 0,
				receiptcompanycode: '',				//当所选中的公司
				visible: false,				//删除的弹窗地址id
				total: toShoppingList ? toShoppingList.checkedCities.length : 0,//商品数量
				pageno: 1,
				pagesize: 10,
				currentPage: 1,
				paycode: '0',				//是否显示支付二维码 0不显示 1显示 
				tradeid: '',				//提交订单编号
				erpcustno: erpcustno,				//公司编码
				creditdata: creditdata&&creditdata!='undefined' ? JSON.parse(creditdata) : '',				//信用付额度
				submission: false,	//禁止重复提交订单
				submissionaddress: false,				//禁止重复提交地址
				// issaleman: issaleman,				//0客户  1业务员
				companyname: companyname,				//公司名称
				companycode: companycode ,//公司编码
				isConfirm:false,//  是否是确认赠品库存不足也下单
				qrCodeUrl:'',
				login: JSON.parse(login),
				cartpublic:'',//购物车明细
			
			};
		},
		
		
		
		created() {
			companyname = sessionStorage.getItem('companyname'); //0客户公司名称
			companycode = sessionStorage.getItem('companycode'); //公司编码
			erpcustno = sessionStorage.getItem('erpcustno');
			this.getTenantid(()=> {
				this.getRichtext()
			})
			toShoppingList = JSON.parse(sessionStorage.getItem('toShoppingArr'));
			if(toShoppingList)this.cartpublic=toShoppingList.cartpublic
			this.next = this.$route.query.next? this.$route.query.next : addtrade;
			if (returngoods == 1) this.$router.push({path:'/cart'})

			if (this.next != 2)
				if (!toShoppingList){
					 this.$router.push({path:'/cart'}) 
				}else{
					
					this.checkedgoods =toShoppingList.checkedCities.slice(0, 10);
					this.total=toShoppingList.checkedCities.length
					this.allcount=toShoppingList.allcount
				}
				
			console.log(this.checkedgoods)
			if (province) {
				this.province = JSON.parse(province);
			} else {
				this.getcitycode();
			}
			
			this.getpaytype();
			this.getquerymycustomer();
		},
		
		methods: {
			//获取支付方式POST 开发完成 /shoppingcart/selectPayment
			getpaytype: function getpaytype() {
				
				var {login}=this
				var companytype = sessionStorage.getItem('companytype'); //公司编码
				this.requset('/shoppingcart/selectPayment', {}).then((data)=>{
					
					console.log('companytype',companytype)
					if(companytype==2){
						var xjpay=[]

						data.data.map((res,index)=>{
							// xjpay.push('res',res)
							if(res.payid==6)xjpay.push(res),this.paytype=xjpay
							if(res.payid==8)xjpay.push(res),this.paytype=xjpay
						})
						console.log('xjpay',xjpay)
						// this.paytype=xjpay
					}else{
						var pau=[]
						data.data.map((res,index)=>{
							if(res.payid!=6&&res.payid!=8)pau.push(res)
						})
						this.paytype=pau
						console.log('xjpay',pau)
						
					}
					
				});
			},
			
			// 获取收货地址列表POST /memberaddressfront/list
			getaddresslist: function getaddresslist() {
				var _this3 = this;
		
				var self = this;
				var Url = '/memberaddressfront/list';
				var data = {
					pageno: 1,
					pagesize: 999999,
					b2bcompanycode: this.companycode,
				};

				this.requset(Url, data).then(function(data) {
					_this3.addresslist = data.data.list;
					var isdefault = true;
					for (var i in data.data.list) {
						if (data.data.list[i].isdefault) {
							_this3.selectaddresskey = i;
							isdefault = false;
							_this3.selectaddress(i);
						}
					}
					if (isdefault) _this3.selectaddresskey = -1;
				});
					
				
				
				
			},
			
			// 选择使用的地址
			selectaddress: function selectaddress(key) {
				this.selectaddresskey = key;
				var data = {
					memberaddressId: this.addresslist[this.selectaddresskey].id
				};
				var Url = '/b2bshoppingcart/updateaddress';
				
				this.requset(Url, data).then(function(res) {});
				
		
			},
			// 选择支付方式
			selectpaytype: function selectpaytype(key) {
				this.selectpaytypekey = key;
			},
			
			// 外层切换公司
			changecompanycode: function changecompanycode(value) {
				var _this4 = this;
		
				var querymycustomer = this.querymycustomer;
				this.receiptcompanycode = value[0];
				console.log(querymycustomer);
		
				for (var i in querymycustomer) {
					if (querymycustomer[i].companycode == value[0]) credit(querymycustomer[i].erpcustno, function(res) {
						_this4.creditdata = res;
					});
				} // sessionStorage.setItem('erpcustno', value[0]);
		
		
				this.getaddresslist(); // erpcustno
			},
			//收货地址里面切换公司
			addresschangecompany: function addresschangecompany(value) {
				console.log(value);
				this.ruleForm.company = value;
			},
			
			
			// 提交订单POST 开发完成 /b2bshoppingcart/addorder
			submitorder() {
				var _this5 = this;
				var textarea = this.textarea,
					selectpaytypekey = this.selectpaytypekey,
					paytype = this.paytype,
					addresslist = this.addresslist,
					selectaddresskey = this.selectaddresskey,
					checkedgoods = this.checkedgoods,
					submission = this.submission;
		
				if (selectaddresskey == -1) {
					this.$message({message: '您还没有选择收货地址',type: 'warning'});
					return;
				}
		
				if (submission) return; //禁止多次提交
		
				this.submission = true;
				var tradeGoods = [];
		
				for (var i in checkedgoods) {
					var obj = {
						goodsno: checkedgoods[i].goodsno,
						barcode: checkedgoods[i].barcode,
						skuid: checkedgoods[i].skuid,
						qty: checkedgoods[i].qty
					};
					tradeGoods.push(obj);
				}
		
				var data = {
					message: textarea,
					payid: paytype.length ? paytype[selectpaytypekey].payid : '',
					confirm:this.isConfirm
				};

				this.requset('/b2bshoppingcart/addorder', data).then((res)=> {
					var orderdata = res.data;
					if (res.status == "success") {
						sessionStorage.removeItem('toShoppingArr');
						this.tradeid = orderdata.tradeid;
						this.cartNumber(); //查询购物车数量
						if (orderdata.flag != 2) {
							console.log('提交',this.paytype[selectpaytypekey].payid)
							if (this.paytype[selectpaytypekey].payid == 1) this.wxpay(); //微信支付
							if (this.paytype[selectpaytypekey].payid == 5) this.xinyongpay(); //信用付
							if (this.paytype[selectpaytypekey].payid == 3) {
								setTimeout(()=>{this.next = 2;},5000)
								;this.Alipay(this.tradeid);
							} //支付宝
							if (this.paytype[selectpaytypekey].payid == 6) this.xinyongpay(); //现金付
							if (this.paytype[selectpaytypekey].payid == 8) this.xinyongpay(); //现金付
							if (this.paytype[selectpaytypekey].payid == 10){
								setTimeout(()=>{this.next = 2;},3000)
								this.unionpay(this.tradeid); //银联付
							}
							 
						} else {
							this.next = 2;
						}
					}else{
						this.submission = false;
						this.$notify.error({title: '失败',message: res.message,duration:2000});
					}
				}).catch(res=>{
					this.submission = false;
					this.$notify.error({title: '失败',message: res.message,duration:2000});
					if(res.message=='赠品库存数量不足')this.openconfirm()
				});
				
			},
			
			
			// 赠品数量不足弹窗
			openconfirm:function openconfirm() {
				var self=this
				this.$confirm('赠品库存不足也下单, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning',
				  customClass:this.color,
				  callback:function(action){
					  console.log(action)
				  	if(action=='confirm'){
				  		self.isConfirm=true
				  		self.submitorder()
				  	}else{
						
					}
				  }
				})
			},
			
			
			
			// 生成微信支付二维码
			wxpay: function wxpay() {
				
				var paydata = {tradeid: this.tradeid,pay: 'pay'};
				this.requset('/wxpay/tradepay', paydata,{met:'pay'}).then(payres=>{
					if (payres.status == "success") {
			
						QRCode.toDataURL(payres.data.code_url)
						  .then(url => {
						    console.log(url)
							this.qrCodeUrl=url
							this.paycode = 1;
						  })
						  .catch(err => {
						    console.error(err)
							this.submission = false;
							this.$notify.error({title: '失败',message: payres.data.return_msg,duration:2000});
						  })	
					}else{
						sessionStorage.setItem('defaultactive','1-1')
						this.$router.push({path:'/member'})
						this.submission = false;
						this.$notify.error({title: '失败',message: payres.message,duration:2000});
					}
				});
				
			},
			
			// 信用支付
			xinyongpay: function xinyongpay() {
				this.credit(this.erpcustno, res=>{
					this.creditdata = res;
				});
				sessionStorage.setItem('defaultactive','1-1')
				this.$router.push({path:'/member'})
			},
			
			// 取消支付
			calcenpay: function calcenpay() {
				this.paycode = '2';
				sessionStorage.setItem('defaultactive','1-1')
				this.$router.push({path:'/member'})
			},
			
			
			// 点击支付完成
			Paymentsuccessful: function Paymentsuccessful() {
				this.requset('/trade/info',  {tradeid: this.tradeid}).then(data=> {
					if (data.status = "success") {
						if (data.data.tradeinfo.flag != 1) {
							this.paycode = '2';
							this.next = 2;
						} else {
							this.$notify.error({title: '支付失败',message: '您还没有支付成功，请先支付后再试！'});
						}
					}
				});
			
			},
			
			// 点击新增地址  /编辑地址
			addressbtn: function addressbtn(item) {
				this.dialogTableVisible = !this.dialogTableVisible;
				var province = sessionStorage.getItem('province'); //省份
		
				if (province) {
					this.province = JSON.parse(province);
				}
		
				if (!province) this.getcitycode();
				var province = this.province;
		
				if (item) {
					for (var i in province) {
						if (province[i].id == item.provinceid) {
							this.getcitycode(province[i].taobaoid, item.cityid, item.districtid);
							this.provinceidname = province[i].name; //选择的省name
						}
					}
		
					this.ruleForm = {
						id: item.id,
						company: item.b2bcompanycode,
						name: item.receiptname,
						phone: item.phone,
						shengid: item.provinceid,
						cityid: item.cityid,
						areaid: item.districtid,
						addressinfo: item.areadetail,
						isdefault: item.isdefault
					};
					this.provinceid = item.provinceid;
					this.cityid = item.cityid;
					this.districtid = item.districtid;
				} else {
					this.empty();
				}
			},
			// 清空
			empty: function empty() {
				this.ruleForm = {
					id: '',
					company: '',
					name: '',
					phone: '',
					shengid: '',
					cityid: '',
					areaid: '',
					addressinfo: '',
					isdefault: false
				};
				this.provinceid = '';
				this.cityid = '';
				this.areaid = '';
				this.provinceidname = '';
				this.cityidname = '';
				this.areaidname = '';
				if (this.$refs['ruleForm']) this.$refs['ruleForm'].clearValidate();
			},
			// 添加地址验证
			updateaddress: function updateaddress(formName, index) {
				console.log(this.ruleForm);
				var self = this;
				this.$refs[formName].validate(function(valid) {
					if (valid) {
						self.saveaddredd();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 点击是否默认地址
			isdefaultbtn: function isdefaultbtn() {
				this.ruleForm.isdefault = !this.ruleForm.isdefault;
			},
			
			// 保存地址POST /memberaddressfront/save
			saveaddredd: function saveaddredd() {
	
				var Url = '/memberaddressfront/save';
				var {ruleForm,province,area,city,submissionaddress,provinceid,cityid,areaid,provinceidname,cityidname,areaidname}=this
				if (submissionaddress) return;
				this.submissionaddress = true;
				var data = {
					id: ruleForm.id,
					receiptname: ruleForm.name,
					address: ruleForm.addressinfo,
					phone: ruleForm.phone,
					provinceid: provinceid,
					cityid: cityid,
					districtid: areaid,
					areadetail: provinceidname + cityidname + areaidname,
					isdefault: ruleForm.isdefault ? 1 : 0,
					// b2bcompanycode: typeof (ruleForm.company) == 'object' ? ruleForm.company[0] : ruleForm.company,
					b2bcompanycode: this.companycode
				};
				
				
				this.requset(Url, data).then((res)=>{
					if (res.status == "success") {
						this.dialogTableVisible = false;
						this.submissionaddress = false;
						this.empty();
						this.getaddresslist(); // this.$refs['ruleForm'].clearValidate();
				
					}
				}).catch(function() {
					this.submissionaddress = false;
				});
	
			},
			
			//在二维码编码前把字符串转换成UTF-8，防止中文乱码
			utf16to8: function utf16to8(str) {
				var out, i, len, c;
				out = "";
				len = str.length;
				for (i = 0; i < len; i++) {
					c = str.charCodeAt(i);
					if (c >= 0x0001 && c <= 0x007F) {
						out += str.charAt(i);
					} else if (c > 0x07FF) {
						out += String.fromCharCode(0xE0 | c >> 12 & 0x0F);
						out += String.fromCharCode(0x80 | c >> 6 & 0x3F);
						out += String.fromCharCode(0x80 | c >> 0 & 0x3F);
					} else {
						out += String.fromCharCode(0xC0 | c >> 6 & 0x1F);
						out += String.fromCharCode(0x80 | c >> 0 & 0x3F);
					}
				}
				return out;
			},
			
			succsee: function succsee(i) {
				if (i) {
					this.$router.push({path:'/index'})
				} else {
					this.$router.push({path:'/member'})
				}
			},
			
			currentchange: function currentchange(e) {
				this.pageno = e;
				var key = e - 1;
				this.checkedgoods = toShoppingList.checkedCities.slice(key * 10, key * 10 + 10);
			},
			
			// 当前是业务员时获取业务员客户 否则默认当前的客户公司
			getquerymycustomer: function getquerymycustomer() {
				this.getaddresslist();
			},
		
			
			
			// 删除收货地址
			deladdress: function deladdress(id) {
				this.requset('/memberaddressfront/del', {id: id}).then((res)=> {
					this.visible = false;
					if (res.status == "success") {
						this.dialogTableVisible = false;
						this.getaddresslist();
					}
				});
			},
			
			
			// 选择省
			handleChangesheng: function handleChangesheng(value) {
				var province = this.province;
				for (var i in province) {
					if (province[i].id == value[0]) {
						this.provinceid = province[i].id;
						this.ruleForm.shengid = province[i].id;
						this.provinceidname = province[i].name;
						this.getcitycode(province[i].taobaoid);
					}
				}
				this.cityid = '';
				this.cityidname = '';
				this.areaid = '';
				this.areaidname = '';
			},
			// 选择市
			handleChangecity: function handleChangecity(value) {
				var city = this.city;
		
				for (var i in city) {
					if (city[i].id == value[0]) {
						console.log(city[i]);
						this.cityid = city[i].id;
						this.cityidname = city[i].name;
						this.getarea(city[i].taobaoid);
					}
				}
			},
			
			
			// 选择区
			handleChangearea: function handleChangearea(value) {
				var area = this.area;
				console.log(value);
		
				for (var i in area) {
					if (area[i].id == value[0]) {
						this.areaid = area[i].id;
						this.areaidname = area[i].name;
						console.log(this.areaid);
						console.log(this.areaidname);
					}
				}
			},
			
			// 获取省市地址  // POST /memberfront/getcitycode
			getcitycode: function getcitycode(code, cityid, areaid) {
				var code = code ? code : 1;
				var Url = '/memberfront/getcitycode';
				this.requset(Url, {code: code}).then(res=>{
						if (res.status == "success") {
							var data = res.data;
							if (code == 1) {
								this.province = data; 
								sessionStorage.setItem('province', JSON.stringify(data));
							} else {
								// 市
								for (var i in data) {
									if (data[i].id == cityid) {
										this.getarea(data[i].taobaoid, areaid);
										this.cityidname = data[i].name; //选择的市name
									}
								}
								if (!cityid) {
									this.getarea(data[0].taobaoid);
									this.ruleForm.cityid = data[0].id;
									this.cityid = data[0].id;
									this.cityidname = data[0].name; //选择的市name
								}
								this.city = data;
							}
						}
					});
				
			},
			
			
			// 获取的区域
			getarea: function getarea(code, areaid) {
					this.requset('/memberfront/getcitycode', {code: code}).then(res=> {
						if (res.status == "success") {
							var data = res.data;
							for (var i in data) {
								if (data[i].id == areaid) {
									this.areaidname = data[i].name; //选择的区name
								}
							}
							if (!areaid) {
								this.ruleForm.areaid = data[0].id;
								this.areaid = data[0].id;
								this.areaidname = data[0].name; //选择的区name
							}
							this.area = data;
						}
					});
			},
		}
	}
</script>

<style>
	.el-textarea__inner{width: 100%;}
	.el-input--suffix ::placeholder {
	            color: #333;
	}
	.tishi{background: #FDF6EC;color: #FF8A00;border-radius: 2px;}
	.el-textarea__inner::placeholder{ color: #999999;}
	.address{background: url('../../assets/img/dzfg.png') no-repeat; background-position: bottom;}
	.tip .el-message-box__close, .el-icon-close:before{color: #666;}
	.el-textarea__inner{color: #333;}
	.el-form-item{margin-bottom: 20px;}
</style>
